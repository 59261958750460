<template>
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="col-sm mg-t-40">
      <!-- <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Topics</label> -->
      <template v-for="(group, key) in groups">
        <div class="divider-text" v-bind:key="'div-' + key">{{key}}</div>
        <ul class="list-inline list-inline-skills" v-bind:key="'ul-' + key">
          <template v-for="topic in group">
            <li class="list-inline-item" v-bind:key="topic.id">
              <router-link :to="{name: 'topics.show', params: {id: topic.id, text: topic.topic}}">
                {{topic.text}}
                <span class="badge mg-l-2 bd-l pd-r-0">{{topic.sermon_count}}</span>
              </router-link>
            </li>
          </template>
        </ul>
      </template>
    </div><!-- col -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  layout: "default",
  metaInfo() {
    return { title: this.$t("topics.default") };
  },
  created () {
    this.$store.dispatch('public/fetchTopics', {sermons_count: true});
  },
  computed: {
    groups: function() {
      return _.groupBy(this.topics, (v) => v.text.charAt(0));
    },
    ...mapGetters({
      topics: 'public/topics'
    })
  },
};
</script>

<style lang="scss" scoped>
.list-inline-skills .list-inline-item a {
  padding-top: 1px;
  padding-bottom: 1px;
}
</style>
